export const convertToKeyValueList = (dataArray, key, value = null) => {
    return dataArray.reduce((acc, current) => {
        acc[current[key]] = value ? current[value] : current;
        return acc;
    }, {});
}

export const formatNumber = (value) => {
    return (new Intl.NumberFormat().format(value))
}

export const avoidMinusNumber = (value) => {
    return value > 0 ? Number(value) : 0;
}

export const isNullOrEmpty = (value) => {
    return value === undefined || value === null || value === "" || value?.toString().trim() === "";
}