import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    facilityList: [],
    selectedFacility: null,
    isDetailView: false,
    isManageView: false,
    selectedFacilityId: null,
};

export const FacilitySlice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        getFacilities: (state, action) => {
            state.facilityList = action.payload;
        },
        addNewFacility: (state, action) => {
            state.facilityList = [...state.facilityList, {...action.payload}];
        },
        updateExistsFacility: (state, action) => {
            const index = state.facilityList.findIndex(i => i.id === action.payload.id);
            state.facilityList[index] = {...action.payload};
        },
        setSelectedFacility: (state, action) => {
            state.selectedFacility = action.payload;
        },
        changeSelectedFacilityId: (state, action) => {
            state.selectedFacilityId = action.payload;
        },
        changeDetailView: (state, action) => {
            state.isDetailView = action.payload;
        },
        changeManageView: (state, action) => {
            state.isManageView = action.payload;
        }
    }
})

export const { getFacilities, addNewFacility, updateExistsFacility, setSelectedFacility, changeSelectedFacilityId, changeDetailView, changeManageView } = FacilitySlice.actions;

export const fetchFacilities = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilities.get(criteria)
        dispatch(getFacilities(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const fetchFacilityById = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilities.getById(id)
        return response;
    } catch (error){
        throw error;
    }
}

export const addFacility = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilities.post(data);
        dispatch(addNewFacility(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const updateFacility = (instance, id, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilities.put(id, data);
        dispatch(updateExistsFacility(response));
        dispatch(setSelectedFacility(response));
        return response;
    }
    catch (error){
        throw error;
    }
}

export const fetchFacility = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilities.getById(id);
        dispatch(setSelectedFacility(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default FacilitySlice.reducer;