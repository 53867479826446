import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    locations: [],
    selectedLocation: null
};

export const FacilityLocationSlice = createSlice({
    name: 'facility-locations',
    initialState,
    reducers: {
        getFacilityLocations: (state, action) => {
            state.locations = action.payload;
        },
        addNewFacilityLocation: (state, action) => {
            state.locations = [...state.locations, {...action.payload}];
        },
        updateExistFacilityLocation: (state, action) => {
            const index = state.locations.findIndex(i => i.id === action.payload.id);
            state.locations[index] = {...action.payload};
        },
        setSelectedFacilityLocation: (state, action) => {
            state.selectedLocation = action.payload;
        }
    }
})

export const { getFacilityLocations, addNewFacilityLocation, updateExistFacilityLocation, setSelectedFacilityLocation } = FacilityLocationSlice.actions;

export const fetchFacilityLocations = (instance, facilityId, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilityLocations.get(facilityId, criteria)
        dispatch(getFacilityLocations(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const addFacilityLocation = (instance, facilityId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilityLocations.post(facilityId, data);
        dispatch(addNewFacilityLocation(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const updateFacilityLocation = (instance, facilityId, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.facilityLocations.put(facilityId, data.id, data);
        dispatch(updateExistFacilityLocation(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default FacilityLocationSlice.reducer;