import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    collectionNotes: [],
    stockItemList: [],
    selectedCollectionNote: null
}

export const CollectionNoteSlice = createSlice({
    name: "collection-notes",
    initialState,
    reducers: {
        getCollectionNotes: (state, action) => {
            state.collectionNotes = action.payload;
        },
        updateExistCollectionNote: (state, action) => {
            const index = state.collectionNotes.findIndex(i => i.id === action.payload);
            state.selectedCollectionNote.active = false;
            state.collectionNotes[index].active = false;
        },
        setStockItemList: (state, action) => {
            const index = state.stockItemList.findIndex(i => i.id === action.payload.id);
            if(index !== -1) {
                state.stockItemList.splice(index, 1);
            } else {
                state.stockItemList = [...state.stockItemList, {...action.payload}];
            }
        },
        setSelectedCollectionNote: (state, action) => {
            state.selectedCollectionNote = action.payload;
        },
        clearStockItemList: (state) => {
            state.stockItemList = [];
        }
    }
})

export const { getCollectionNotes, setStockItemList, clearStockItemList, updateExistCollectionNote, setSelectedCollectionNote } = CollectionNoteSlice.actions;

export const fetchCollectionNotes = (instance, criteria) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.collectionNotes.get(criteria);
        dispatch(getCollectionNotes(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const fetchCollectionNote = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.collectionNotes.getById(id);
        dispatch(setSelectedCollectionNote(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const cancelCollectionNote = (instance, id) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.collectionNotes.put(id);
        dispatch(updateExistCollectionNote(id));
        return response;
    } catch (error){
        throw error;
    }
}

export const addCollectionNote = (instance, data) => async () => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.collectionNotes.post(data);
        return response;
    } catch (error){
        throw error;
    }
}

export default CollectionNoteSlice.reducer;